import React, {useState} from 'react'
import { Context } from './context'
import { hot } from 'react-hot-loader'
import { Element } from 'react-scroll';
import { images } from './helpers'
import {
  Navigation,
  IntroSection,
  Personal,
  Tomato,
  Hello,
  Pharma,
  Retail,
  Drive,
  Advantages,
  Contact,
  Footer,
} from './sections';
const App = () => {
  const [isHamburgerOpened, setIsHamburgerOpened] = useState(0);

  return (
    <Context.Provider value={{
      isHamburgerOpened,
      setIsHamburgerOpened
    }}>
      <main>
        <Navigation />
        <IntroSection />
        <Personal />

        <Element name="solutions">
          <Tomato />
        </Element>

        <Hello />
        <Pharma />
        <Retail />
        <Drive />

        <Element name="advantages">
          <Advantages />
        </Element>

        <Element name="contact">
          <Contact />
        </Element>

        <Footer />


      </main>
    </Context.Provider>
  )
}

export default  hot(module)(App)
// export default  App
