
import React from 'react'
import { images } from '../helpers'
import Reveal, { Fade, Roll } from 'react-reveal';

const Pharma = () => {
  const data = {
    title: 'Pharma Agent',
    description: 'Образовательная платформа для фармацевтической промышленности. Список лекарств, стандарты, сезонные списки, генерация тестов и многое другое только в вашем смартфоне.',
    buttonText: 'PDF-презентация'
  }

  return (
    <div className="pharma">
      <div className="container  container--pharma">

      <div className="wrap xl-12 lg-12 md-1 xl-gutter-16 xl-flexbox xl-between xl-top md-center">

        <div className="col xl-5-12 lg-6-12 md-10-12">
          <Roll left duration={600} delay={200}>
            <img
              src={images.pharma.logoBar}
              className="pharma__logo-bar"
            />
          </Roll>
          <div className="pharma__icon" />
          <div className="pharma__title">{ data.title }</div>
          <div className="pharma__desc">{ data.description }</div>


          <button className="pharma__button">
            <span>{ data.buttonText }</span>
          </button>
        </div>

        <div className="col xl-6-12 lg-6-12 md-10-12  pharma__media">
          <div className="pharma__oval-rect" />
            {/* <Fade bottom > */}
              <img
                src={images.pharma.ovalBg}
                className="pharma__oval-bg"
              />
            {/* </Fade> */}
            <Fade bottom>
              <div className="pharma__mobile-container">
                <img
                  src={images.pharma.elMobile}
                  className="pharma__mobile"
                />`
              </div>
            </Fade>
            <Fade bottom delay={700}>
              <img
                src={images.pharma.elContact}
                className="pharma__contact"
              />
            </Fade>
            <Fade bottom delay={1000}>
              <img
                src={images.pharma.elUnits}
                className="pharma__units"
              />
            </Fade>
        </div>
      </div>



      </div> {/* EOF .container */}
    </div>
  )
}

export default Pharma
