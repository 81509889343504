
import React, {useContext} from 'react'
import {Context} from '../context'

const Hamburger = () => {
  const {isHamburgerOpened, setIsHamburgerOpened} = useContext(Context);

  const hamburgerClasses = `
    hamburger
    ${isHamburgerOpened === 1 ? 'isOpened' : ''}
  `;

  const handleHamburgerClick = () => {
    isHamburgerOpened === 1 ? setIsHamburgerOpened(0) : setIsHamburgerOpened(1)
  }

  return (
    <div className={hamburgerClasses} onClick={handleHamburgerClick}>
      <a className="hamburger__essence" href="#">
        <i className="hamburger__i"/>
      </a>
    </div>
  )
}

export default Hamburger
