import React, { useContext, Fragment, useState } from 'react';
import { Link, animateScroll } from 'react-scroll';
import { images } from '../helpers'
import {Context} from '../context'
import {
  Hamburger,
} from '../components';

const Navigation = () => {

  // const [isOpened, setIsOpened] = useState(false);
  const {isHamburgerOpened, setIsHamburgerOpened} = useContext(Context);

  console.log('is hamba: ', isHamburgerOpened);

  const navClasses = `
    nav
    ${isHamburgerOpened === 1 ? 'isDropDown' : ''}
  `;

  const overlayClasses = `
    nav-overlay
    ${isHamburgerOpened === 1 ? 'active' : ''}
  `;

  const handleOverlayClick = () => {
    if (isHamburgerOpened === 1) {
      setIsHamburgerOpened(0)
    }
  }

  return (
    <React.Fragment>
      <div
        className={overlayClasses}
        onClick={handleOverlayClick}
      />
      <div className={navClasses}>

        <div className="container  container--nav">



          <div className="nav__menu">

            <Hamburger/>


            <a href="/" className="nav__logo-link">
              <img
                src={images.intro.logo}
                className="logo"
              />
            </a>

            <ul className="nav__list">

              <Link
                onClick={handleOverlayClick}
                to="advantages"
                spy={true}
                smooth={true}
                offset={-80}
                duration={1000}
                className="nav__item-link"
              >
                <li className="nav__item  nav__item--active">Почему ServiceLMS?</li>
              </Link>

              <Link
                onClick={handleOverlayClick}
                to="solutions"
                spy={true}
                smooth={true}
                offset={-180}
                duration={1000}
                className="nav__item-link"
              >
                <li className="nav__item">Наши решения</li>
              </Link>

              <Link
                onClick={handleOverlayClick}
                to="contact"
                spy={true}
                smooth={true}
                offset={-80}
                duration={1000}
                className="nav__item-link"
              >
                <li className="nav__item">Контакты</li>
              </Link>

              <div className="copyright">© 2019 ServiceLMS. All Rights Reserved</div>
            </ul>
          </div>

          <Link
            onClick={handleOverlayClick}
            to="contact"
            spy={true}
            smooth={true}
            offset={-80}
            duration={1000}
            className="nav__btn-link"
          >
            <button className="nav__btn">
              <span>Заказать LMS</span>
            </button>
          </Link>
        </div>


      </div>
    </React.Fragment>
  );
};

export default Navigation;
