
import React from 'react'
import { images } from '../helpers'
import Reveal, { Fade, Roll } from 'react-reveal';

const Retail = () => {
  const data = {
    title: 'Mr. Retail',
    description: 'Цифровая система обучения, охватывающая все основы обслуживания розничных клиентов. Тестирование, удобный интерфейс, таблица лидеров, понятная статистика,  и многие другие удивительные функции делают Mr. Retail неотъемлемой частью вашего бизнеса.',
  }

  return (
    <div className="retail">
      <div className="container  container--retail">

        <div className="wrap xl-12 lg-12 md-12 xl-gutter-16 xl-flexbox xl-between xl-top md-center">
          <div className="col xl-6-12 lg-6-12 md-10-12">
            <div className="retail__media">
              <div className="retail__oval-rect" />

              <img
                src={images.retail.ovalBg}
                className="retail__oval-bg"
              />

              <Fade bottom>
                <div className="retail__mobile-container">
                  <img
                    src={images.retail.elMobile}
                    className="retail__mobile"
                  />
                </div>
              </Fade>

              <Fade bottom delay={700}>
                <img
                  src={images.retail.elWinners}
                  className="retail__winners"
                />
              </Fade>

              <Fade bottom delay={1000}>
                <img
                  src={images.retail.elCategories}
                  className="retail__categories"
                />
              </Fade>
            </div>
          </div>
          <div className="col xl-5-12 lg-5-12 md-10-12 md-first">
            <Roll left duration={600} delay={200}>
              <img
                src={images.retail.logoBar}
                className="retail__logo-bar"
                alt="Hello Hello"
              />
            </Roll>
            <div className="retail__title">{ data.title }</div>
            <div className="retail__desc">{ data.description }</div>
          </div>
        </div>





      </div> {/* EOF .container */}
    </div>
  )
}

export default Retail
