import React from 'react'
import { images } from '../helpers'

const IntroSection = () => {
  return (
    <div className="header">
      <div className="container  container--header">

        <div className="wrap xl-auto xl-gutter-16 xl-flexbox xl-between xl-top sm-1">
          <div className="col xl-7-12 xl-first sm-1-1 sm-last">
            <div className="header-content">
              <div className="header-content__title">
                Готовые LMS* для вашего бизнеса с применением <span className="header-content__title-span">искусственного интеллекта</span>
              </div>
              <div className="header-content__subtitle">
                *LSM - Learning System Management - Система управления обучением
              </div>
              <p className="header-content__description">
                Мы помогаем компаниям достичь своих бизнес-целей с помощью наших передовых технологических
                  решений. Наши инновационные образовательные приложения для персонала позволят вам
                  выделиться среди конкурентов и увеличить прибыль вашего бизнеса!
              </p>
            </div>
          </div>
          <div className="col xl-5-12 sm-1-1 sm-first">
            <div className="header-media">
              <img
                src={images.intro.bg}
                className="header-media__bg"
              />

              <img
                src={images.intro.item01}
                className="header-media__item-01"
              />

              <img
                src={images.intro.item02}
                className="header-media__item-02"
              />

              <img
                src={images.intro.item03}
                className="header-media__item-03"
              />

              <img
                src={images.intro.character}
                className="header-media__character"
                alt="Tomato App"
              />

              <img
                src={images.intro.phone}
                className="header-media__phone"
                alt="Tomato App"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <a href="#" className="try-banner">
          <div className="try-banner__content">
            <div className="try-banner__title">
              Прокачать персонал с помощью ServiceLMS
            </div>
            <svg
              className="try-banner__icon"
              width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="32" cy="32" r="32" fill="#F2F4F8"/>
              <circle className="try-banner__inner-circle" cx="32" cy="32" r="24" />
              <path className="try-banner__icon-arrow" d="M31.8593 31.6034L51.1779 37.5859L30.9542 37.5347L27.4525 33.9657L31.8593 31.6034Z" fill="#1A2024"/>
            </svg>
          </div>
          <div className="try-banner__button">
            <span>Попробовать бесплатно</span>
          </div>
        </a>
      </div>
    </div>
  )
}

export default IntroSection
