
import React from 'react'
import { images } from '../helpers'
import Reveal, { Fade } from 'react-reveal';

const Advantages = () => {

  return (
    <div className="advantages">
      <div className="container  container--advantages">

        <div className="advantages__content">
          <div className="advantages__title">Наши преимущества</div>
          <div className="advantages__description">
            Единственный простой и эффективный способ обучить
            ваших сотрудников. Будь то ресторан или логистическая
            компания, мы предоставляем широкий спектр настраиваемых
            инструментов в соответствии с вашей бизнес-моделью.
          </div>
        </div>

        <div className="wrap xl-3 xl-gutter-16 xl-flexbox xl-top lg-top xl-between lg-flexbox lg-center md-1">
          <Fade right delay={1000}>
            <div className="col xl-1-3 lg-1-2  advantages__item">
              <img
                src={images.advantages.elem01}
                alt="Инновационность"
              />
            </div>
          </Fade>
          <Fade right delay={1100}>
            <div className="col xl-1-3 lg-1-2  advantages__item">
              <img
                src={images.advantages.elem02}
                alt="Универсальность"
              />
            </div>
          </Fade>
          <Fade right delay={1200}>
            <div className="col xl-1-3 lg-1-2  advantages__item">
              <img
                src={images.advantages.elem03}
                alt="Прозрачность"
              />
            </div>
          </Fade>

        </div>

      </div> {/* EOF .container */}
    </div>
  )
}

export default Advantages
