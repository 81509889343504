
import React from 'react'
import { images } from '../helpers'
import Reveal, { Fade, Roll } from 'react-reveal';

const Hello = () => {
  const data = {
    title: 'Hello Hello',
    subtitle: 'Обучение для поколения Instagram',
    description: 'Система управления процессом обучения, разработанная специально под нужды колл-центров.',
    buttonText: 'PDF-презентация'
  }

  return (
    <div className="hello">
      <div className="container  container--hello">

      <div className="wrap xl-12 md-12 xl-gutter-16 xl-flexbox xl-between xl-top md-center">

        <div className="col xl-6-12 md-10-12">
          <div className="hello__media">
            <div className="hello__oval-rect" />

            <img
              src={images.hello.ovalBg}
              className="hello__oval-bg"
            />

            <Fade bottom>
              <div className="hello__mobile-container">
                <img
                  src={images.hello.elMobile}
                  className="hello__mobile"
                />
              </div>
            </Fade>

            <Fade bottom delay={700}>
              <img
                src={images.hello.elRate}
                className="hello__rate"
              />
            </Fade>

            <Fade bottom delay={1000}>
              <img
                src={images.hello.elIntro}
                className="hello__introduction"
              />
            </Fade>
          </div>
        </div>
        <div className="col xl-5-12 md-10-12 md-first">
          <Roll left duration={600} delay={200}>
            <img
              src={images.hello.logoBar}
              className="hello__logo-bar"
              alt="Hello Hello"
            />
          </Roll>
          <div className="hello__title">{ data.title }</div>
          <div className="hello__subtitle">{ data.subtitle }</div>
          <div className="hello__desc">{ data.description }</div>
          <button className="hello__button">
            <span>{ data.buttonText }</span>
          </button>
        </div>

      </div>

      </div> {/* EOF .container */}
    </div>
  )
}

export default Hello
