const images = {
  intro: {
    bg: require('../assets/images/header-media__bg.svg'),
    item01: require('../assets/images/header-media__item-01.svg'),
    item02: require('../assets/images/header-media__item-02.svg'),
    item03: require('../assets/images/header-media__item-03.svg'),
    character: require('../assets/images/header-media__character.png'),
    phone: require('../assets/images/header-media__phone.png'),
    logo: require('../assets/images/logo.svg')
  },
  personal: {
    dotsBg: require('../assets/images/personal__dots-bg.svg'),
    woman: require('../assets/images/personal__woman.svg'),
    students: require('../assets/images/personal__students.svg'),
    elem01: require('../assets/images/personal__elem01.svg'),
    elem02: require('../assets/images/personal__elem02.svg'),
    elem03: require('../assets/images/personal__elem03.svg'),

  },
  tomato: {
    logoBar: require('../assets/images/tomato__logo-bar.svg'),
    ovalBg: require('../assets/images/bg-oval--right-sided.svg'),
    mobile: require('../assets/images/tomato__elem-01--handy.png'),
    dishes: require('../assets/images/tomato__elem-02--dishes.png'),
  },
  hello: {
    logoBar: require('../assets/images/hello__logo-bar.svg'),
    ovalBg: require('../assets/images/bg-oval--left-sided.svg'),
    elMobile: require('../assets/images/hello__elem-01--mobile.png'),
    elRate: require('../assets/images/hello__elem-02--rate.png'),
    elIntro: require('../assets/images/hello__elem-03--introduction.png'),
  },
  pharma: {
    logoBar: require('../assets/images/pharma__logo-bar.svg'),
    ovalBg: require('../assets/images/pharma__bg-oval.svg'),
    elMobile: require('../assets/images/pharma__elem-01--mobile.png'),
    elContact: require('../assets/images/pharma__elem-02--contact.png'),
    elUnits: require('../assets/images/pharma__elem-03--units.png'),
  },
  retail: {
    logoBar: require('../assets/images/retail__logo-bar.svg'),
    ovalBg: require('../assets/images/bg-oval--left-sided.svg'),
    elMobile: require('../assets/images/retail__elem-01--mobile.png'),
    elWinners: require('../assets/images/retail__elem-02--winners.png'),
    elCategories: require('../assets/images/retail__elem-03--categories.png'),
  },
  drive: {
    logoBar: require('../assets/images/drive__logo-bar.svg'),
    ovalBg: require('../assets/images/drive__bg-oval.svg'),
    elMobile: require('../assets/images/drive__elem-01--mobile.png'),
    elRank: require('../assets/images/drive__elem-02--rank.png'),
    elPoll: require('../assets/images/drive__elem-03--poll.png'),
  },
  advantages: {
    elem01: require('../assets/images/advantages-01.png'),
    elem02: require('../assets/images/advantages-02.png'),
    elem03: require('../assets/images/advantages-03.png'),
  },
  contact: {
    bg: require('../assets/images/contact__bg.svg'),
    elemWindow: require('../assets/images/contact__elem-window.svg'),
    elemHuman: require('../assets/images/contact__elem-human.svg'),
  },
  misc: {
    contactPopupImage: require('../assets/images/contact-popup__image.png'),
  }
};

export default images;
