import React from 'react'
import { render } from 'react-dom'
import App from "./App";
import './styles/index.scss'

function renderApp(){
  render(
    <App/>,
    document.getElementById('root')
  )
}

renderApp()

module.hot.accept(renderApp)
