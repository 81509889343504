
import React from 'react'
import { images } from '../helpers'
import Reveal, { Fade, Roll } from 'react-reveal';

const Drive = () => {
  const data = {
    title: '2Drive',
    description: 'Единственный простой и эффективный способ обучить ваших водителей. Будь то служба такси или грузовая компания, мы предоставляем широкий спектр настраиваемых инструментов в соответствии с вашей бизнес-моделью.',
  }

  return (
    <div className="drive">
      <div className="container  container--drive">

        <div className="wrap xl-12 lg-12 md-1 xl-gutter-16 xl-flexbox xl-between xl-top md-center">
          <div className="col xl-5-12 lg-6-12 md-10-12">
            <Roll left duration={600} delay={200}>
              <img
                src={images.drive.logoBar}
                className="drive__logo-bar"
                alt="2Drive"
              />
            </Roll>
            <div className="drive__icon" />
            <div className="drive__title">{ data.title }</div>
            <div className="drive__desc">{ data.description }</div>

          </div>
          <div className="col xl-6-12 lg-6-12 md-10-12">
            <div className="drive__media">
              <div className="drive__oval-rect" />

              <img
                src={images.drive.ovalBg}
                className="drive__oval-bg"
              />

              <Fade bottom>
                <div className="drive__mobile-container">
                  <img
                    src={images.drive.elMobile}
                    className="drive__mobile"
                  />
                </div>
              </Fade>
              <Fade bottom delay={700}>
                <img
                  src={images.drive.elRank}
                  className="drive__rank"
                />
              </Fade>
              <Fade bottom delay={1000}>
                <img
                  src={images.drive.elPoll}
                  className="drive__poll"
                />
              </Fade>
            </div>
          </div>
        </div>






      </div> {/* EOF .container */}
    </div>
  )
}

export default Drive
