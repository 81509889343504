
import React from 'react'
import { images } from '../helpers'

const Personal = () => {
  return (
    <div className="personal">
      <div className="container">

        <div className="wrap xl-12 lg-1 xl-gutter-16 xl-flexbox xl-center lg-center">
          <div className="col xl-10-12 lg-1-1">

            <div className="personal__title">Мы помогаем бизнесу сделать свой персонал<br />
              <span className="personal__title-span">образованным и удовлетворенным</span>
            </div>

            <div className="personal__subtitle">Мы используем новейшие методики обмена знаниями в наших программных решениях, экономя ваше время и деньги.</div>


            <div className="personal-media">

              <div
                className="personal-media__bg"
                style={{
                  backgroundImage: `url(${images.personal.dotsBg})`,
                  backgroundRepeat: 'no-repeat',
                }}>
                <img
                  style={{visibility: 'hidden'}}
                  src={images.personal.dotsBg}
                  className="personal-2222"
                />

              </div>

              <img
                src={images.personal.woman}
                className="personal-media__woman"
              />

              <img
                src={images.personal.students}
                className="personal-media__students"
              />

              <img
                src={images.personal.elem01}
                className="personal-media__elem01"
              />

              <img
                src={images.personal.elem02}
                className="personal-media__elem02"
              />

              <img
                src={images.personal.elem03}
                className="personal-media__elem03"
              />

            </div>

          </div>
        </div>

      </div> {/* EOF .container */}
    </div>
  )
}

export default Personal
