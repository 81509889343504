
import React from 'react'

const Footer = () => {

  return (
    <div className="footer">
      <div className="container  container--footer">
          <div className="footer__copy">
            © 2019 ServiceLMS. All Rights Reserved
          </div>

          <div className="footer__prod">
            Designed by <span className="footer__prod-span">MockupGuys</span>
          </div>
          </div> {/* EOF .container */}
    </div>
  )
}

export default Footer
