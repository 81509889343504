import Navigation from './Navigation';
import IntroSection from './IntroSection';
import Personal from './Personal';
import Tomato from './Tomato';
import Hello from './Hello';
import Pharma from './Pharma';
import Retail from './Retail';
import Drive from './Drive';
import Advantages from './Advantages';
import Footer from './Footer';
import Contact from './Contact';

export {
  Navigation,
  IntroSection,
  Personal,
  Tomato,
  Hello,
  Pharma,
  Retail,
  Drive,
  Advantages,
  Footer,
  Contact,
};
