
import React, { Component } from 'react'
import InputMask from 'react-input-mask';
import { images } from '../helpers'

class PhoneInput extends React.Component {
  render() {
    return <InputMask {...this.props} mask="+3\8 (099) 999 99 99" maskChar=" " />;
  }
}


class Contact extends Component {

  constructor (props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      errors: {
        nameErr: '',
        emailErr: '',
        phoneErr: ''
      },
      nameValid: false,
      emailValid: false,
      phoneValid: false,
      formValid: false,
      submitionPopupIsShown: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log('form submitted and');
  }

  handlechange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    const validEmailRegex =
      RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

    switch (name) {
      case 'name':
        errors.nameErr =
          value.length < 4
            ? 'Full Name must be 5+ characters long!'
            : '';
        break;
      case 'email':
        errors.emailErr =
          validEmailRegex.test(value)
            ? ''
            : 'Email is not valid!';
        break;
      case 'phone':
        errors.phoneErr =
          value.length < 8
            ? 'phone must be 8 characters long!'
            : '';
        break;
      default:
        break;
    }

    this.setState({errors, [name]: value}, ()=> {
        console.log(errors)
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    console.log('serialized === ', serializeParams({
      email: this.state.email,
      phone: this.state.phone,
      name: this.state.name,
    }));
    if(this.validateForm(this.state.errors)) {
      console.info('Valid Form, yo!')
      this.setState({
        submitionPopupIsShown: true
      })

      // 1. Create a new XMLHttpRequest object
      let xhr = new XMLHttpRequest();

      // 2. Configure it: GET-request for the URL /article/.../load
      xhr.open('GET', 'https://servicelms.com/Subscribe?' + `email=${this.state.email}&phone=${this.state.phone}&name=${this.state.name}`);

      // 3. Send the request over the network
      xhr.send();

      // 4. This will be called after the response is received
      xhr.onload = function() {
        if (xhr.status != 200) { // analyze HTTP status of the response
          console.log(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
        } else { // show the result
          console.log(`Done, got ${xhr.response.length} bytes`); // responseText is the server
        }
      };

      xhr.onprogress = function(event) {
        if (event.lengthComputable) {
          console.log(`Received ${event.loaded} of ${event.total} bytes`);
        } else {
          console.log(`Received ${event.loaded} bytes`); // no Content-Length
        }

      };

      xhr.onerror = function() {
        alert("Request failed");
      };

    } else{
      console.error('Invalid Form, please make it properly')
    }
  };

  validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  };

  closePopup = () => {
    this.setState({
      submitionPopupIsShown: false
    })
  };

  render() {
    const popupClasses = `
      contact-popup
      ${this.state.submitionPopupIsShown ? 'active' : ''}
    `;

    return (
      <div className="contact">
        <div className="container  container--contact">
          <div className="wrap xl-12 xl-gutter-16 xl-top xl-flexbox xl-between md-1">
            <div className="col xl-6-12 xl-first">

              <form className="contact-form" onSubmit={this.handleSubmit}>

                <div className="contact-form__title">
                  Остались вопросы?
                </div>

                {/* Name */}
                <div className="contact-form__item">
                  <input
                    className="contact-form__input"
                    onChange={this.handlechange}
                    value={this.state.name}
                    name="name"
                    type="text"
                    placeholder="Имя"
                  />
                </div>
                {/* email */}
                <div className="contact-form__item">
                  <input
                    className="contact-form__input"
                    onChange={this.handlechange}
                    value={this.state.email}
                    name="email"
                    type="text"
                    placeholder="Email" />
                  </div>
                {/* phone */}
                <div className="contact-form__item">

                  <PhoneInput
                    className="contact-form__input  contact-form__input--phone"
                    placeholder="Телефон"
                    onChange={this.handlechange}
                    value={this.state.phone}
                    name="phone"
                  />
                  <span className="contact-form__error">Обязательное поле*</span>
                </div>

                <input className="contact-form__button" type="submit" value="Отправить"/>

              </form>

            </div>
            <div className="col xl-offset-1-1 xl-5-12">

              <div className="contact-media">

                {/* bg */}
                <img
                  src={images.contact.bg}
                  className="contact-media__bg-dots"
                />

                {/* img-left */}
                <img
                  src={images.contact.elemWindow}
                  className="contact-media__elem-window"
                />
                {/* img-human */}
                <img
                  src={images.contact.elemHuman}
                  className="contact-media__elem-human"
                />

              </div>

            </div>
          </div>

          <div className={popupClasses}>
            <div className="contact-popup__essence">
              <div onClick={this.closePopup} className="contact-popup__close" />
              <div className="contact-popup__title">
                Спасибо за обращение!<br />
                Мы свяжемся с вами в ближайшее время
              </div>
              <img
                src={images.misc.contactPopupImage}
                className="contact-popup__image"
                alt="Спасибо за обращение!"
              />
            </div>

            <div className="contact-popup__overlay">

            </div>
          </div>

        </div> {/* EOF .container */}
      </div>
    )
  }
};

export default Contact;
