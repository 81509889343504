
import React from 'react'
import { images } from '../helpers'
import Reveal, { Fade, Roll } from 'react-reveal';

const Tomato = () => {
  return (
    <div className="tomato">
      <div className="container  container--tomato">
      <div className="wrap xl-12 md-12 xl-gutter-16 xl-flexbox xl-between xl-top md-center">

        <div className="col xl-5-12 md-10-12">
          <Roll left duration={600} delay={200}>
            <img
              src={images.tomato.logoBar}
              className="tomato__logo-bar"
              alt="Mr. Tomato"
            />
          </Roll>
          <div className="tomato__icon" />
          <div className="tomato__title">Mr. Tomato</div>
          <div className="tomato__subtitle">Обучение для поколения Instagram</div>

          <div className="tomato__desc">Інноваційний мобільний навчальний додаток для персоналу ресторанів, який вигідно виділить вас серед конкурентів і збільшить прибуток вашого закладу!</div>


          <button className="tomato__button">
            <span>mrtomato.io</span>
          </button>
        </div>
        <div className="col xl-6-12 md-10-12">
          <div className="tomato__media">
            <div className="tomato__oval-rect" />

            <img
              src={images.tomato.ovalBg}
              className="tomato__oval-bg"
            />

            <Fade bottom>
              <div className="tomato__mobile-container">
                <img
                  src={images.tomato.mobile}
                  className="tomato__mobile"
                />
              </div>
            </Fade>

            <Fade bottom delay={700}>
              <img
                src={images.tomato.dishes}
                className="tomato__dishes"
              />
            </Fade>
          </div>
        </div>
      </div>

      </div> {/* EOF .container */}
    </div>
  )
}

export default Tomato
